.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-target {
  cursor: pointer;
}

.tooltip-box {
  position: absolute;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  text-align: center;
  padding: 0.5rem;
  border-radius: 15px;
  white-space: nowrap;
  z-index: 10;
  visibility: visible;
  opacity: 0.9;
}

.tooltip-box::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
}
