.selectPerfil-container {
  display: flex;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  margin: 5% auto;
  width: 80%;
  max-width: 420px;
  border-radius: 30px;
  height: 600px;
}

.selectPerfil-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
}

.selectPerfil-form label {
  font-size: 26px;
  margin-bottom: 10px;
  display: block;
  width: 100%;
  margin-bottom: 50px;
}

.submit-button-login:hover {
    background-color: #01c5df;
 }

.selectPerfil-form img {
  width: 100%;
  max-width: 280px;
  height: auto;
  margin-bottom: 70px;
}

.selectPerfil-form select {
  border: 1px solid #F1F1F1;
  border-radius: 13px;
  width: 100%;
}

.submit-button-login {
  background-color: #00ACC1;
  width: 100%;
}

.submit-button-login:hover {
  background: #00ACC1;
}

.submit-button-login:active {
  background: #00ACC1;
  animation: blink 0.3s ease;
}
