.container {
    margin: 20px auto;
    max-width: 1200px;
    padding: 20px;
    box-sizing: border-box;
}

.container h1 {
    font-size: 1.8rem;
    margin-bottom: 15px;
    color: #333;
    position: relative;
    padding-bottom: 5px; /* Espaço para a linha */
    border-bottom: 2px solid #333; /* Linha abaixo do título */
    display: inline-block; /* Garante que a linha não ocupe toda a largura */
}
