* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.login-background {
  width: 100%;
  height: 98.5vh;
  background: url('Assets/Profe_fundo.png') no-repeat center center;
  background-size: 100% 100%;
  margin: 0;
  padding: 0;
}

.login-container {
  display: grid;
  grid-template-columns: repeat(2, minmax(min(100%, 450px), 1fr));
  gap: 30%;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
 }

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFF;
  max-width: 400px;
  max-height: 550px;
  width: 100%;
  height: 100%;
  margin: 0 20px;
  border-radius: 35px;
  box-shadow: 0px 4px 10.1px 0px rgba(0, 0, 0, 0.15);
  padding: 50px 0px;
}

.login-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-image img {
  margin-left: 650px;
  max-width: 500px;
  max-height: 550px;
  width: 100%;
  height: 100%;
  height: auto;
}

.login-form img {
  width: 80%;
  object-fit: contain;
  margin-bottom: 25px;
}

.login-form h2 {
  text-align: center;
  font-size: 1.9em;
}

.form-container {
  width: 90%;
}

.form-container input {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: none;
  background: #F3F3F3;
  margin-bottom: 10px;
  padding-left: 5px;
  font-size: 18px;
}

.password {
  position: relative;
  display: inline-block;
}

.eye-button {
  position: absolute;
  right: 10px;
  top: 45%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  color: #4d4444;
}

.eye-button:hover {
  background: transparent;
}

.link-form {
  font-size: 14px;
  color: #106B76;
  text-decoration: none;
}

.separator {
  margin: 0 10px;
  color: #0B4046;
}

@media (max-width: 768px) {
  .login-container {
    grid-template-columns: repeat(2, minmax(min(100%, 350px), 1fr));
    gap: 30%;
  }

  .login-form {
    padding: 50px 30px;
  }
}

@media (max-width: 480px) {
  .login-container {
    grid-template-columns: repeat(2, minmax(min(100%, 250px), 1fr));
    gap: 30%;
  }

  .login-form h2 {
    font-size: 1.2em;
  }

  .form-container input {
    width: 100%;
    font-size: 16px;
  }
}
