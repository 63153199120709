.menu-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 25px;
  }

.content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  height: 60px;
}

.button-menu {
  padding: 10px 0;
  margin-right: 0;
  border: none;
  cursor: pointer;
  text-align: center;
  border-radius: 0px 0px 15px 15px;
}

.menu-items {
  display: grid;
  background-color: #FFF;
  border-radius: 15px;
  box-shadow: 10px 6px 8px rgba(0, 0, 0, 0.3);
}

.content-type {
  width: 100%;
  height: 100%;
}

.large-icon {
  font-size: 50px;
}

.title-button {
  font-size: 15px;
  background-color: transparent;
  border: none;
}

.card-text {
  margin: 0;
}

.title-p {
  margin: 0;
  height: 10px;
}

.total-p {
  font-size: 45px;
  height: 40px;
}

.description-p {
  font-weight: bold;
  margin-top: 10px;
  font-size: 14px;
}

.contrato-p {
  margin-top: 10px;
  font-size: 14px;
}

.cargo-p {
  margin-top: 10px;
  font-size: 14px;
}

.title-bottom {
  margin-bottom: 35px;
}

.translate {
  transform: translateY(-5px);
}

.font-large {
  font-size: 32px;
  font-weight: bold;
}

.font-small {
  font-size: 30px;
}

.tamanho-icon {
  font-size: 100px;
  color: #0062bc;
  padding-left: 10px;
  padding-top: 15px;
}

.tamanho-icon-off {
  font-size: 100px;
  color: grey;
  padding-left: 10px;
  padding-top: 15px;
}

.tamanho-icon-especial {
  font-size: 100px;
  color: #0062bc;
  padding-left: 10px;
  padding-top: 15px;
}

@media (max-width: 768px) {
  .title-p {
    font-size: 20px;
  }

  .title-e {
    font-size: 20px;
  }

  .tamanho-icon {
    font-size: 100px;
  }

  .tamanho-icon-especial {
    font-size: 120px;
  }

  .menu-container {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
  }
}
