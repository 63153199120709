.custom-buttons-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
  
  .custom-buttons-container button {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: rgb(0, 172, 193);
    transition: background-color 0.3s;
  }
  
  .custom-buttons-container button:hover {
    background-color: rgb(0, 150, 170);
  }
  
  .custom-buttons-container button:active {
    background-color: rgb(0, 120, 140);
  }
  
  .custom-buttons-container .cancel-button {
    background-color: rgb(251, 4, 4);
  }
  
  .custom-buttons-container .cancel-button:hover {
    background-color: rgb(220, 0, 0);
  }
  