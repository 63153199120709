.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Fundo escuro semitransparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%; /* Limita a largura a 90% da tela */
  max-width: 600px; /* Limita a largura máxima */
  max-height: 80vh; /* Limita a altura máxima a 80% da altura da tela */
  overflow: hidden; /* Garante que a barra de rolagem apareça apenas dentro do conteúdo interno */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Adiciona sombra */
}

.lista-motivos {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: calc(80vh - 150px); /* Calcula altura máxima da lista com base na altura disponível */
  overflow-y: auto; /* Habilita a rolagem vertical para a lista */
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
}

.lista-motivos li {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.lista-motivos li input {
  margin-right: 10px;
}

input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

button {
  margin: 10px 5px 0 0;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
