.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  text-align: center;
  width: 500px;
}

.texto {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  width: 100%;
}

.texto2 {
  text-align: justify;
  font-size: 18px;
  margin-bottom: 1rem;
}

.radio-buttons {
  display:flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.motivo-select {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.opcao {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0.5rem;
}