form {
  max-width: 600px;
  margin: auto;
  padding: 1em;
}

form div + div {
  margin-top: 1em;
}

label {
  display: inline-block;
  width: 100%;
  text-align: left;
  margin-bottom: .5em;
}

input, select, textarea {
  font-size: 1.4em;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 15px;
  background-color: #ebebeb;
}

input, select {
  height: 35px;
}

.inline-inputs {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.inline-checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-top: 0px;
}

.checkbox-group {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
}

.label-icon {
  display: flex;
  justify-content: 'space-between';
  align-items: 'center';
  width: '100%';
}

.inline-inputs > div {
  flex: 1;
  margin-right: 1em;
}

.inline-inputs > div:last-child {
  margin-right: 0;
}

button {
  padding: 0.7em;
  color: #fff;
  background-color: #007BFF;
  border: none;
  border-radius: 15px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

